var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"box BOX-card"},[_c('div'),_c('div',{staticClass:"tzggtitle"},[_c('div',{staticStyle:{"width":"240px","cursor":"pointer"},on:{"click":_vm.goBack}},[_c('i',{staticClass:"el-icon-back",staticStyle:{"font-size":"25px"}}),_c('span',[_vm._v(" 上架商品")])])]),_c('div',{staticClass:"infobox"},[_c('div',{staticClass:"dialog-content-box"},_vm._l((_vm.platformList),function(item,index){return _c('div',{key:index,staticClass:"pl-box-item",class:item.value==_vm.platform?'pl-active':'',on:{"click":function($event){return _vm.choseP(item.value)}}},[_c('img',{staticClass:"pl-image",attrs:{"src":item.image}}),_c('div',{staticClass:"pl-name"},[_vm._v(_vm._s(item.name))])])}),0),_c('div',{staticClass:"leftbox"},[_c('div',{staticClass:"infotitle"},[_vm._v("选择商品")]),_c('div',{staticClass:"ch-box"},[_c('div',{staticClass:"left-card",staticStyle:{"width":"20%","padding":"10px"}},[_c('el-table',{staticStyle:{"width":"100%","background-color":"#ecf2f9"},attrs:{"data":_vm.groupList,"max-height":"500","header-cell-style":{
                            backgroundColor: '#ECF2F9',
                            color: '#000',
                            textAlign:'center',
                            fontSize: '16px',
                            height:'40px'
                          }},on:{"selection-change":_vm.handlegroupChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"80"}}),_c('el-table-column',{attrs:{"prop":"name","label":"分组列表"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"nickname"},[_vm._v(_vm._s(scope.row.name))])]}}])})],1),(_vm.goodsList.length<=0)?_c('el-empty',{staticStyle:{"width":"200px","height":"200px","margin":"0 auto"},attrs:{"description":" "}}):_vm._e()],1),_c('div',{staticClass:"left-card",staticStyle:{"width":"30%"}},[_c('el-table',{staticStyle:{"width":"100%","background-color":"#ecf2f9"},attrs:{"data":_vm.accountList,"max-height":"500","header-cell-style":{
                            backgroundColor: '#ECF2F9',
                            color: '#000',
                            textAlign:'center',
                            fontSize: '16px',
                            height:'40px'
                          }},on:{"selection-change":_vm.handleAccountChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"80"}}),_c('el-table-column',{attrs:{"prop":"name","label":"账号列表"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('img',{staticClass:"hoverImg",attrs:{"src":scope.row.avatar}}),_c('span',{staticClass:"nickname"},[_vm._v(_vm._s(scope.row.nickname))])]}}])})],1),(_vm.goodsList.length<=0)?_c('el-empty',{staticStyle:{"width":"200px","height":"200px","margin":"0 auto"},attrs:{"description":" "}}):_vm._e()],1),_c('div',{staticClass:"right-card",staticStyle:{"width":"50%"}},[_c('div',[_c('el-table',{staticStyle:{"width":"100%","background-color":"#ecf2f9"},attrs:{"data":_vm.goodsList,"max-height":"500","header-cell-style":{
                            backgroundColor: '#ECF2F9',
                            color: '#000',
                            textAlign:'center',
                            fontSize: '16px',
                          }},on:{"selection-change":_vm.handleSceneChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"80"}}),_c('el-table-column',{attrs:{"prop":"goods_thumb","label":"商品图","min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"display":"flex"}},[(scope.row.thumb_image)?_c('img',{staticStyle:{"width":"80px","height":"80px","border-radius":"8px"},attrs:{"src":scope.row.thumb_image}}):_vm._e()])]}}])}),_c('el-table-column',{attrs:{"prop":"title","label":"关联商品","min-width":"260"}})],1),(_vm.goodsList.length<=0)?_c('el-empty',{staticStyle:{"width":"200px","height":"200px","margin":"0 auto"},attrs:{"description":" "}}):_vm._e()],1)])])])]),_c('div',{staticClass:"botmbtnbox"},[_c('div',{staticClass:"bigbtn",on:{"click":_vm.createTask}},[_vm._v("立即上架")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }