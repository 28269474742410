
<template>
    <div>
            <div class="box BOX-card">
              <div></div>
                <div class="tzggtitle">
                    <!-- <img class="tzggicon" src="@/assets/hld.png" alt="" /> -->
                     <div style="width: 240px;cursor: pointer;" @click="goBack">
                      <i class="el-icon-back " style="font-size: 25px;"></i>
                      <span> 添加创作计划</span>
                     </div>
                    
                </div>
                <div class="infobox">
                  <div style="display: flex;width: 100%;justify-content: space-between;">
                    <div class="leftbox" style="width: 49%;">
                        <div class="infotitle">基础信息</div>
                        <div style="display: flex;">
                          <div class="leftbtm" style="width: 100%;justify-content: space-between;">
                          <div >
                              <div class="fabubox">
                                  <div style="width: 180px;"><span style="color: red">*</span> 创作计划名称</div>
                                  <div class="inputbox">
                                      <el-input v-model="form.title"  placeholder="请输入创作计划名称"   ></el-input>
                                  </div>
                              </div>
                              <div class="guazbox">
                                  <div style="width: 180px;"><span style="color: red">*</span> 选择视频内容</div>
                                  <div class="radiobox" style="display: flex;justify-content: flex-start;">
                                      <el-radio v-model="form.video_type" label="0">图文视频</el-radio>
                                      <!-- <el-radio v-model="form.video_type" label="1">剪辑视频</el-radio> -->
                                  </div>
                              </div>
                              <div class="guazbox" v-if="form.video_type==0" >
                                      <div style="width: 180px;">
                                          <span style="color: red">*</span> 图片数量
                                          <el-tooltip class="item" effect="dark" content="生成图文时图片数量,不可超过商品图片总数量" placement="top-start">
                                              <i class="el-icon-question"></i>
                                          </el-tooltip>
                                      </div>
                                  
                                  <div class="inputbox white-back">
                                      <el-input-number v-model="form.images_count" :step="1"></el-input-number>
                                  </div>
                              </div>
                              <div class="guazbox" v-if="form.video_type==1" >
                                      <div style="width: 180px;">
                                          <span style="color: red">*</span> 视频时长(秒)
                                          <el-tooltip class="item" effect="dark" content="生成视频的时间长度" placement="top-start">
                                              <i class="el-icon-question"></i>
                                          </el-tooltip>
                                      </div>
                                  
                                  <div class="inputbox white-back">
                                      <el-input-number v-model="form.video_times" :step="1"></el-input-number>
                                  </div>
                              </div>
                              <div class="guazbox">
                                  <div style="width: 135px;"><span style="color: red">*</span>
                                      挂载购物车
                                      <el-tooltip class="item" effect="dark"  placement="top">
                                              <i class="el-icon-question"></i>
                                              <div slot="content">
                                                          <div>挂小黄车的规则:</div><br/>
                                                          <div>1千粉丝以下，一周挂一次小黄车</div><br/>
                                                          <div>1千~3千粉丝，，一天能挂2次小黄车</div><br/>
                                                          <div>3千~1万粉丝，一天挂5次小黄车</div><br/>
                                                          <div>1万粉丝以上，一天可以挂10次小黄车</div>
                                              </div>
                                      </el-tooltip>
                                  </div>
                                  <div class="radiobox" style="display: flex;justify-content: space-around;">
                                      <el-radio v-model="form.is_cart" label="1">挂载</el-radio>
                                      <el-radio v-model="form.is_cart" label="0">不挂载</el-radio>
                                     
                                  </div>
                              </div>

                              <div class="guazbox"  >
                                      <div style="width: 180px;">
                                          <span style="color: red">*</span> 视频生成数量
                                          <el-tooltip class="item" effect="dark" content="单次任务生成数量不能超过,单平台绑定账号数量" placement="top-start">
                                              <i class="el-icon-question"></i>
                                          </el-tooltip>
                                      </div>
                                  <div class="inputbox white-back">
                                      <el-input-number v-model="form.video_count" :step="1"  :min="1"></el-input-number>
                                  </div>
                              </div>

                              <div class="guazbox" style="width: 100%">
                              <div><span style="color: red">*</span> 平台发布方式</div>
                              <div class="inputbox" style="width: 140px">
                                <el-dropdown @command="handleCommand">
                                  <span class="el-dropdown-link" style="padding: 20px; font-size: 16px; font-weight: 400">
                                    {{ selectedPlatform.name }}<i class="el-icon-arrow-down el-icon--right"></i>
                                  </span>
                                  <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item v-for="platform in platforms" :key="platform.id" :command="platform.id">
                                      {{ platform.name }}
                                    </el-dropdown-item>
                                  </el-dropdown-menu>
                                </el-dropdown>
                              </div>
                             
                            </div>

                            <div class="guazbox" style="width: 100%">
                              <div><span style="color: red">*</span> 选择发布日期</div>
                              <el-date-picker
                                style="margin-left: 20px;"
                                v-model="form.publishDate"
                                type="date"
                                placeholder="选择发布日期"
                                :picker-options="datePickerOptions"
                              >
                              </el-date-picker>
                            </div>
                            
                            <div class="guazbox" style="width: 100%">
                              <div><span style="color: red">*</span> 视频创作时间段</div>
                              <el-button type="primary" @click="openTimeConfigDrawer" style="margin-left: 20px;;">配置时间段</el-button>
                            </div>

                          </div>
                          <div class="xzshop" >
                             <img class="tzggicon" v-if="form.goods_id && goods.thumb_image" :src="goods.thumb_image" alt=""  @click="chooseModal=true"/>
                             <img class="tzggicon" v-else src="@/assets/xzshop.png" alt=""  @click="chooseModal=true"/>
                             <span>推广商品</span>
                          </div>
                        </div>
                       
                        </div>
                    </div>
   
                    
                    <div class="leftbox" style="width: 49%;">
                          <div class="infotitle" style="display: flex; justify-content: space-between;">
                            <div>话题设置</div>
                            <div class="title-box-opr kt-flex kt-space-around">
                              <el-button class="addBtn add-btn" @click="topicModal=true" type="primary" size="small" :loading="loading">添加话题</el-button>
                            </div>
                          </div>
                          <div class="table-box">
                              <el-table
                                :data="topicList"
                                style="width: 100%; background-color: #ecf2f9"
                                max-height="300"
                                :header-cell-style="{
                                  backgroundColor: '#ECF2F9',
                                  color: '#000',
                                  textAlign:'center',
                                  fontSize: '16px',
                                  height:'40px'
                                }"
                                @selection-change="handleSelectionChange"
                              >
                                <el-table-column prop="title" label="话题内容" min-width="260">
                                  <template slot-scope="scope">
                                    <el-tooltip effect="dark"  placement="top"  >
                                      <div slot="content" class="tooltip-item">
                                        {{ scope.row.title }}
                                      </div>
                                      <span class="tooltip-text">{{ scope.row.title }}</span>
                                    </el-tooltip>
                                  </template>
                                </el-table-column>
                              
                                <el-table-column label="操作" min-width="160">
                                  <template slot-scope="scope">
                                      <el-link style="color:red;" @click="deleteTopic(scope.row.id)" >删除</el-link>
                                  </template>
                                </el-table-column>
                              
                              </el-table>
                              <el-empty v-if="topicList.length<=0" description=" " style="width: 200px;height: 200px;margin: 0 auto;"></el-empty>
                          </div>
                        
                        </div>  

                  </div>

                  <div style="display: flex;width: 100%;justify-content: space-between;">
                      <div class="leftbox" style="width: 49%;">
                          <div class="infotitle" style="display: flex; justify-content: space-between;">
                            <div>视频标题</div>
                            <div class="title-box-opr kt-flex kt-space-around">
                              <el-input style="width: 240px;margin-right: 10px;"  v-model="keyword" placeholder="请填写关键词"></el-input>
                              <el-input-number style="width: 120px;margin-right: 10px;"  v-model="form.video_count" :step="1"  :min="1"></el-input-number>
                              <el-button class="addBtn add-btn" @click="createTitle"  :loading="loading">AI智能创作</el-button>
                            </div>
                          </div>
                          <div class="table-box">
                              <el-table
                                :data="titleList"
                                style="width: 100%; background-color: #ecf2f9"
                                max-height="300"
                                :header-cell-style="{
                                  backgroundColor: '#ECF2F9',
                                  color: '#000',
                                  textAlign:'center',
                                  fontSize: '16px',
                                  height:'40px'
                                }"
                                @selection-change="handleSelectionChange"
                              >
                                <el-table-column prop="name" label="视频文案" min-width="260">
                                  <template slot-scope="scope">
                                    <el-tooltip effect="dark"  placement="top"  >
                                      <div slot="content" class="tooltip-item">
                                        {{ scope.row.name }}
                                      </div>
                                        <div class="video-title">
                                          {{ scope.row.name }}
                                        </div>
                                    
                                    </el-tooltip>
                                  </template>
                                </el-table-column>
                              
                                <el-table-column label="操作" min-width="160">
                                  <template slot-scope="scope">
                                    
                                      <el-link style="color:#2758FF;" v-if="scope.row.is_add==0" >添加</el-link>
                                      <el-link style="color:#c0c4cc;" v-else >已添加</el-link>
                                  </template>
                                </el-table-column>
                              
                              </el-table>
                              <el-empty v-if="titleList.length<=0" description=" " style="width: 200px;height: 200px;margin: 0 auto;"></el-empty>
                          </div>
                        
                        </div>
                      <!-- 背景图标签---->
                      <div class="leftbox" style="width: 49%;">
                        <div class="infotitle"><span>背景设置</span><span style="font-size: 12px;color:#c0c4cc;margin-left: 20px;font-weight: normal;">请更具商品特性选择适当的背景类目</span></div>
                        <div class="ch-box">
                            <div class="left-card" style="width: 20%;">
                              <el-tree  :data="backData" :props="defaultProps" show-checkbox accordion icon-class="el-icon-caret-right"  @node-click="handleNodeClickBack" @check-change="handleCheckChange"> </el-tree>
                            </div>
                            <div class="right-card" style="width: 80%;">
                                <div class="card-con card-img-box">
                                    <div class="item-img" v-for="(item,index) in backList">
                                      <img class="back-img" :src="item.url_image">
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                  </div>

                  <!--- 场景设置-->
                  <div class="leftbox" style="display: none;">
                        <div class="infotitle">场景设置</div>
                        <div class="ch-box">
                            <div class="left-card">
                              <el-tree  :data="categroyData" :props="defaultProps" accordion icon-class="el-icon-caret-right"  @node-click="handleNodeClick"> </el-tree>
                            </div>
                            <div class="right-card">
                                <div class="card-con">

                                  <el-table
                              :data="sceneList"
                              style="width: 100%; background-color: #ecf2f9"
                              max-height="500"
                              :header-cell-style="{
                                backgroundColor: '#ECF2F9',
                                color: '#000',
                                textAlign:'center',
                                fontSize: '16px',
                                height:'40px'
                              }"
                              @selection-change="handleSceneChange"
                            >
                            <el-table-column type="selection" width="80"> </el-table-column>
                              <el-table-column prop="name" label="场景内容" min-width="260">
                                <template slot-scope="scope">
                                  <el-tooltip effect="dark"  placement="top"  >
                                    <div slot="content" class="tooltip-item">
                                      {{ scope.row.prompt }}
                                    </div>
                                      <div class="scene-title">
                                        {{ scope.row.prompt }}
                                      </div>
                                    >
                                  </el-tooltip>
                                </template>
                              </el-table-column>
                            </el-table>
                            <el-empty v-if="sceneList.length<=0" description=" " style="width: 200px;height: 200px;margin: 0 auto;"></el-empty>

                                </div>
                            </div>
                        </div>
                        <div class="tags-box">
                           <div class="tags-box-item" v-for="(item,index) in keyword_ids" :key="index">
                            <el-tooltip effect="dark"  placement="top-start"  >
                                    <span class="tags-title">{{ item.prompt }}</span>
                                    <div slot="content" class="tooltip-item">
                                      {{ item.prompt }}
                                    </div>
                                      
                            </el-tooltip>
                            <div @click="delScene(index)" style="font-size: 28px;color:#fff;cursor: pointer;" >×</div>
                          </div>
                        </div>
                 
                  </div>

                  <!--- 场景设置-->


                  
                    
                </div>

                <div class="botmbtnbox">
                    
                    <div class="bigbtn" style="" @click="createTask">创建任务</div>
                </div>
            </div>
    

        <!-- 选择推广商品-->
        <!-- 添加组件-->
    <el-dialog title="" :visible.sync="chooseModal" width="70%" custom-class="dialog-box-cl" top="12vh" >
     <goodsList  @send-data="setGoods" ></goodsList>
    </el-dialog>
     <!-- 添加组件-->
       <!-- 时间配置抽屉组件 -->
       <TimeConfigDrawer ref="timeConfigDrawer" @save="handleTimeConfigSave" />
     
       <el-dialog title="" :visible.sync="topicModal" width="30%" custom-class="dialog-box-cl" top="12vh" >
        <div class="dialog-content-box">
          <h1 style="margin: 20px 0 0 20px;font-size: 24px;border-bottom: 1px solid #ccc;padding-bottom: 10px;">添加话题</h1>
            <div style="margin: 40px auto;display: flex;align-items: center;justify-content: center;">
              <label style="width: 100px;">话题名称：</label>
              <el-input v-model="topic" placeholder="请输入话题名称,一行输入一条内容" type="textarea" :rows="10" style="width: 60%;" ></el-input>
            </div>

            <div style="width: 100%;display: flex;justify-self: center">
              <el-button class="addBtn " style="width: 200px;margin: 40px auto 0;" @click="topicAdd"  >添加</el-button>
            </div>
          </div>
       </el-dialog>
      
    </div>
  </template>
<script>
import goodsList from '@/components/goodsList.vue';
import TimeConfigDrawer from './TimeConfigDrawer.vue';
  import {maxCountApi,createGoodsTitleApi,goodsTitleListApi,createTaskApi,sceneCateApi,sceneListApi,backCateApi,backListApi} from '@/api/api.js';
  import{createTopicApi,topicListApi,topicDelApi} from '@/api/shop.js';
  export default {
    components:{goodsList,TimeConfigDrawer},
    props: ['addModal'],
    data() {
      return {
        topicModal:false,
        chooseModal:false,
        goodsDrawerModal:false,
        form: {
          title: "",
          video_type:'0',
          goods_id:0,
          images_count:4,
          video_times:7,
          is_cart:'1',
          total_video_count:1,
          video_count:10,
          title_count:'',
          is_publish:'0',
          title_ids:[],
          keyword_ids:[],
          back_cate_ids:[],
          timeConfig: null,
          publishDate: null,
          platform:'fasthand',
        },
        goods:[],
        radio: "1",
        num: 1,
        tableList: [ ],
        goodsList:[],
        goods:{},
        titleList:[],
        keyword:'',
        loading:false,
        categroyData:[],
        defaultProps: {
          children: 'children',
          label: 'label',
          value:'value'
        },
        sceneList:[],
        keyword_ids:[],
        backData:[],
        backList:[],
        back_ids:[],
        topicList:[],
        topic:'',
        datePickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now() - 8.64e7; // 禁用今天之前的日期
          },
        },
        platforms: [
        { id: 'fasthand', name: '快手', code: 'fasthand' },
        { id: 'douyin', name: '抖音', code: 'douyin' },
        { id: 'redbook', name: '小红书', code: 'redbook' }
      ],
      selectedPlatform: { id: 'fasthand', name: '快手', code: 'fasthand' }
    
      };
     
    },
  
    mounted() {
  
      //this.open();
      this.getInfo();
      this.getSceneCateList();
      this.getBackCateList();
      this.generateTitle();
    },
    methods: {
      topicAdd(){
        if(!this.form.goods_id){
          return this.$message.error('请先选择商品');
        }
        createTopicApi({topic:this.topic,goods_id:this.form.goods_id}).then(res=>{
          this.getTopicList();
        })
      },
      deleteTopic(id){
        topicDelApi({id:id}).then(res=>{
          this.getTopicList();
        })
      },
      getTopicList(){
        let that=this;
        topicListApi({goods_id:this.form.goods_id}).then(res=>{
          that.topicList=res.data;
        })
      
      },
      handleCommand(platformId) {
      this.selectedPlatform = this.platforms.find(p => p.id === platformId)
      console.log('选中的平台:', this.selectedPlatform.name)
      console.log('平台识别码:', this.selectedPlatform.code)
      this.form.platform=this.selectedPlatform.code;
    },
      formatDate(date) {
        if (!date) return '';
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
      generateTitle() {
        // 获取当前日期和时间
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        
        // 生成随机数（这里生成一个4位数的随机数）
        const randomNum = Math.floor(1000 + Math.random() * 9000);
        
        // 组合标题
        const title = `${year}${month}${day}-任务计划${randomNum}`;
        this.form.title=title;
        return title;
      },
      openTimeConfigDrawer() {
        this.$refs.timeConfigDrawer.showDrawer();
      },
      handleTimeConfigSave(config) {
        this.form.timeConfig = config;
        // 可以在这里处理保存后的逻辑，比如更新UI或显示提示信息
        this.$message.success('时间配置已更新');
      },
      delScene(index){
        this.keyword_ids.splice(index,1);
      },
      handleSceneChange(val){
        let that=this;
        
        val.forEach(res => {
          that.keyword_ids.push(res)
        });
        that.keyword_ids=that.deduplicatedArray(that.keyword_ids);
      },
      handleNodeClick(data) {
        let that=this;
        sceneListApi({category_id:data.value}).then(res=>{
          that.sceneList=res.data;
        });
      },
      //点击加载内容
      handleNodeClickBack(data) {
        let that=this;
        console.log('开始加载，',data);
        backListApi({category_id:data.value}).then(res=>{
          that.backList=res.data;
        });
      },
      //选择分类
      handleCheckChange(data, checked, indeterminate){
        
        let that=this;
        if(checked==true){
          this.handleNodeClickBack(data);
          that.back_ids.push(data.value);
          data.children.map(res=>{
            that.back_ids.push(res.value);
          })
        }else{
          that.back_ids=that.back_ids.filter(item=>{
            return item!=data.value;
          })
        }
        that.back_ids=that.deduplicatedArray(that.back_ids);
        
      },
      open() {
        this.$confirm('根据我国互联网法律法规明确规定，禁止发布不良信息、传播违法内容。我们非常重视网络环境的健康，一旦发现有用户发布不良内容，将予以严肃处理。 请勿发布或传播不良内容，如有违反，一律封号并起诉追究相关法律责任，请严格遵守相关法律法规和平台规则。', '温馨提示', {
          confirmButtonText: '阅读并同意',
          showCancelButton:false,
          distinguishCancelAndClose:false,
          closeOnClickModal:false,
          closeOnPressEscape:false,
          closeOnHashChange:false,
          showClose:false,
          customClass:'n-message-tips',
          title:'温馨提示',
          type: 'warning'
        }).then(() => {
          
        }).catch(() => {
                 
        });
      },

      getInfo(){
        let that=this;
        maxCountApi().then(res=>{
          if(res.data.total_video_count){
            //that.form.video_count=res.data.total_video_count;
            //that.form.total_video_count=res.data.total_video_count;
            //that.form.title_count=res.data.total_video_count;
          }
        });
      },

      handleChange(value) {
       
      },
      handleSelectionChange() {},
      checkSelectSet(row, index) {
        //row 就是每行的数据
        //return : false  就是禁用
        //return : true  不禁用
        return row.id != this.form.goods_id
      },
      createTask(){
        let that=this;
        if(!that.form.title){
          return this.$message.error('任务名称不能为空');
        }
        if(!that.form.goods_id){
          return this.$message.error('商品内容不能为空');
        }
        
        if(that.keyword_ids.length>that.form.video_count){
          return this.$message.error('选择的场景内容不能超过生成的视频数量');
        }
        //重置场景数据
        let keywords=[];
        that.keyword_ids.map(item=>{
          keywords.push(item.id);
        })
    
        that.form.keyword_ids=keywords;
        that.form.back_cate_ids=that.back_ids;

        if (this.form.timeConfig && this.form.timeConfig.publishTimeMode === '2') {
          // 处理快手定时发布的逻辑
          const timeSlots = this.form.timeConfig.timeSlots.map(slot => ({
            label: slot.label,
            startTime: slot.startTime,
            endTime: slot.endTime
          }));
          
          // 将 timeSlots 添加到要发送给后端的数据中
          this.form.publishTimeSlots = timeSlots;
        }
        this.form.publishDate=this.formatDate(this.form.publishDate);
        createTaskApi(that.form).then(res=>{
          that.$message.success(res.msg);
          setTimeout(() => {
            that.goBack();
         }, 800);

        
        });
      },
      goBack(){
        this.$router.go(-1);
      },
      //选取商品
      setGoods(goods){
        this.form.goods_id=goods.id;
        this.goods=goods;
        this.keyword=goods.title;
        this.chooseModal=false;
        this.getGoodsTitleList();
        this.getTopicList();
      },
      //获取文案
      getGoodsTitleList(){
        let that=this;
        goodsTitleListApi({goods_id:that.goods.id}).then(res=>{
          that.titleList=res.data;
          that.form.title_ids = that.removeDuplicates(this.titleList.map(item => item.id));
        });
      },
      getSceneCateList(){
        let that=this;
        sceneCateApi().then(res=>{
          that.categroyData=res.data;
        });
      },
      getBackCateList(){
        let that=this;
        backCateApi().then(res=>{
          that.backData=res.data;
        });
      },
      //创作文案
      createTitle(){
        let that=this;
        if(!that.keyword){
          return that.$message.error('请填写关键词');
        }
        that.loading=true;
        createGoodsTitleApi({keyword:that.keyword,title_count:that.form.title_count,goods_id:that.goods.id}).then(res=>{
            if(res.code==1){
              that.titleList=res.data;
              that.loading=false;
              that.form.title_ids = that.removeDuplicates(this.titleList.map(item => item.id));
            }
        });
      },
      //数组去重
      removeDuplicates(arr) {
        return [...new Set(arr)];
      },
      deduplicatedArray(originalArray) {
            // Flatten the multi-dimensional array into a one-dimensional array
            const flatArray =originalArray.flat();

            // Remove duplicates using a Set
            const uniqueArray = [...new Set(flatArray)];

            // Optionally, you can convert the unique array back to multi-dimensional array
            // Here, we just return the unique array for simplicity
            return uniqueArray;
        }

    },
  };
  </script>
    
    <style scoped lang="scss">
    :deep(.el-table td), 
  :deep(.el-tabl th) {
    text-align: center !important;
  }
  :deep(.el-tree-node__content){
    height: 46px;
  }
  :deep(.el-tree-node__content .el-tree-node__label){
    font-size: 16px;
    font-weight: 700;
  }
  :deep(.el-tree-node__content:hover){
    background-color:#f4f5f9;
    border-radius: 8px;
  }
  :deep(.el-tree-node__expand-icon){
    font-size: 20px;
    color: #333;
  }

  .ch-box{
    width: 100%;
    min-height: 400px;
    display: flex;
    justify-content: space-around;
    .left-card{
      width: 30%;
      margin: 20px 0 0 40px;
      height: 500px;
      overflow-y: auto;
      border-right: 2px solid #f5f5f5;
    }
    .right-card{
      width: 68%;
      padding:10px;
      .card-con{
        background-color: #ECF2F9;
        height: 500px;
        border-radius: 10px;

        
      }
    }
  }
  .card-img-box{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 500px;
    overflow-y: auto;
    .item-img{
        position: relative;
        width: calc(24% - 5px);
        height: fit-content;
        line-height: 1;
        margin: 5px;
        border-radius: 8px;
        overflow: hidden;
        background: #F5F5F5;
        cursor: pointer;
        transform-origin: 50% 0;
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
   
        .back-img{
          width: 138px;
          height: 192px;
        }
      }
  }

  .video-title{
    white-space: nowrap;       /* 禁止文本换行 */
    overflow: hidden;          /* 隐藏超出容器的部分 */
    text-overflow: ellipsis;   /* 使用省略号表示溢出部分 */
    width: 300px;    
  }
  .tags-box{
    display: flex;
    justify-content: center;
    display: grid;
            grid-template-columns: repeat(6, 1fr); /* 每行显示10个元素 */
            gap: 10px; /* 元素之间的间距 */
            margin: 10px;
  }
  .tags-box-item{
    padding: 5px 15px;
    color: #fff;
    background: #B4C5FF;
    margin: 10px;
    border-radius: 50px;
    white-space: nowrap;      /* 禁止换行 */
     overflow: hidden;         /* 隐藏超出部分 */
     text-overflow: ellipsis;  /* 用省略号代替溢出部分 */
     display: flex;
     align-items: center;
    
  }
  .tags-title{
     white-space: nowrap;      /* 禁止换行 */
     overflow: hidden;         /* 隐藏超出部分 */
     text-overflow: ellipsis;  /* 用省略号代替溢出部分 */
      
   }
  
  .scene-title{
    white-space: nowrap;       /* 禁止文本换行 */
    overflow: hidden;          /* 隐藏超出容器的部分 */
    text-overflow: ellipsis;   /* 使用省略号表示溢出部分 */
    display: flex;
    justify-content: flex-start;
  }
  .tooltip-item{
    width: 300px; 
    word-wrap: break-word;    /* 长单词换行 */
    white-space: normal;      /* 正常的空白处理，允许换行 */
  }
    .BOX-card{
        border-radius: 10px;;
    }
    .white-back{
        background: #ffffff !important;
    }
    .table-box{
      padding:20px;
    }
    .el-table{
      border-radius: 10px;
    }
    .el-table .warning-row {
      background: oldlace;
    }

    .el-table .success-row {
      background: #f0f9eb;
    }
  .box {
    width: 100%;
    // height: calc(100vh - 100px);
    padding:  20px 40px;
    background: #e1eaf2;
    display: flex;
    flex-direction: column;
    
    .tzggtitle {
      font-size: 20px;
      color: #000;
      display: flex;
      align-items: center;
      margin: 10px 0;
    }
    .tzggicon {
      width: 6px;
      height: 18px;
      margin-right: 4px;
    }
    .right-box{
      min-height: 300px;
      width: 50%;
      margin-left: 80px;
      h1{
        font-size: 16px;
        font-weight: 700;
        padding:10px;
        text-align: center;
      }
    }
  
    .infobox {
      width: 100%;
      // height: 280px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .leftbox {
        // height: 280px;
        background-color: #fff;
        border-radius: 10px;
        width:100%;
        margin-bottom: 40px;
        .leftbtm {
          padding: 20px 40px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          // justify-content: space-between;
          .xzshop {
           padding:20px;
            // background: #ecf2f9;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-left: 40px;
            img {
              width: 200px;
              height: 200px;
              margin-bottom: 10px;
              border-radius: 10px;
            }
            img:hover {
              cursor: pointer;
            }
          }
        }
  
        .guazbox {
          width: 360px;
          display: flex;
          align-items: center;
          font-weight: bold;
          margin: 30px 0;
          .radiobox {
            flex: 1;
            padding: 0 10px;
            box-sizing: border-box;
          }
        }
  
        .fabubox {
          width: 360px;
          display: flex;
          align-items: center;
          font-weight: bold;
          display: flex;
          justify-content: space-between;
  
          .inputbox {
            height: 40px;
            width: 230px;
            display: flex;
            background: #ecf2f9;
            align-items: center;
            // justify-content: space-around;
            color: #2758ff;
            font-size: 16px;
            padding: 0 6px;
            border-radius: 3px;
            margin-left: 10px;
  
            ::v-deep .el-input__inner {
              background: #ecf2f9;
              border: none;
            }
          }
        }
      }
    }
  }
  
  .inputbox {
    height: 40px;
    width: 230px;
    display: flex;
    background: #ecf2f9;
    align-items: center;
    // justify-content: space-around;
    color: #2758ff;
    font-size: 16px;
    padding: 0 6px;
    border-radius: 3px;
    margin-left: 10px;
  
    ::v-deep .el-input__inner {
      background: #ecf2f9;
      border: none;
    }
  }
  
  .infotitle {
    font-size: 16px;
    color: #000;
    font-weight: 700;
    width: 100%;
    height: 68px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d8d8d8;
    padding: 20px;
    box-sizing: border-box;
  }
  .fabubox {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
  }
  
  .botmbtnbox {
    width: 100%;
    height: 90px;
    // background: #ffffff;
    // box-shadow: 0px 0px 12px 0px rgba(43, 108, 143, 0.1);
    margin-top: -30px;
    z-index: 88 !important;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  
    .bigbtn:hover {
      cursor: pointer;
    }
    .bigbtn {
      height: 50px;
      border-radius: 8px;
      border: 1px solid #000000;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px;
      background: #2758ff; color: #fff;border:none;width: 400px;margin: 0 auto;
    }
  }
  
  .fabubtm {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .fabubtmleft {
      width: 65%;
      padding: 20px;
      box-sizing: border-box;
    }
    .fabubtmright {
      width: 35%;
      padding: 20px;
      box-sizing: border-box;
      .headbox {
        width: 100%;
        display: flex;
        justify-content: space-between;
  
        font-weight: 400;
        font-size: 20px;
        color: #000000;
        span {
          color: #2758ff;
        }
      }
  
      .rightbtm {
        width: 100%;
        padding: 10px 0;
        overflow-y: auto;
        height: 350px;
        .heng {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 10px 0;
        }
      }
    }
  }

  .add-btn{
    height: 40px !important;
    display: flex;
    align-items: center;
    width: 100px;
    justify-content: center;
    font-size: 14px;
  }
  </style>
    