<template>
    <el-drawer
      title="视频发布时间配置"
      :visible.sync="drawerVisible"
      direction="rtl"
      size="50%"
      :before-close="handleClose"
    >
      <div class="drawer-content">
        <el-form :model="config" label-width="120px">
          <el-form-item label="发布时间">
            <el-radio-group v-model="config.publishTimeMode">
              <el-radio label="1">不限制</el-radio>
              <el-radio label="2">7点到23点创作段</el-radio>

            </el-radio-group>
          </el-form-item>
  
          <template v-if="config.publishTimeMode === '2'">
            <el-form-item 
              v-for="(item, index) in config.timeSlots" 
              :key="index" 
              :label="'时间 ' + (index + 1)"
            >
              <el-time-picker
                v-model="item.startTime"
                placeholder="开始时间"
                format="HH:mm"
                value-format="HH:mm"
                style="width: 120px; margin-right: 10px;"
              ></el-time-picker>
              <el-time-picker
                v-model="item.endTime"
                placeholder="结束时间"
                format="HH:mm"
                value-format="HH:mm"
                style="width: 120px; margin-right: 10px;"
              ></el-time-picker>
              <el-button 
                type="danger" 
                icon="el-icon-delete" 
                circle 
                @click="removeTimeSlot(index)" 
                size="mini" 
                style="margin-left: 10px;"
              ></el-button>
            </el-form-item>
  
            <el-form-item label="添加新时间段">
              <el-time-picker
                v-model="newSlot.startTime"
                placeholder="开始时间"
                format="HH:mm"
                value-format="HH:mm"
                style="width: 120px; margin-right: 10px;"
              ></el-time-picker>
              <el-time-picker
                v-model="newSlot.endTime"
                placeholder="结束时间"
                format="HH:mm"
                value-format="HH:mm"
                style="width: 120px; margin-right: 10px;"
              ></el-time-picker>
              <el-button type="primary" @click="addTimeSlot">添加</el-button>
            </el-form-item>
          </template>
        </el-form>
      </div>
      <div class="drawer-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="saveConfig">确定</el-button>
      </div>
    </el-drawer>
  </template>
  
  <script>
  export default {
    data() {
      return {
        drawerVisible: false,
        config: {
          publishTimeMode: '2',
          timeSlots: [
            { startTime: '07:00', endTime: '07:20' },
            { startTime: '08:00', endTime: '08:20' },
            { startTime: '09:00', endTime: '09:20' },
            { startTime: '12:00', endTime: '12:20' },
            { startTime: '13:00', endTime: '13:20' },
            { startTime: '14:00', endTime: '14:20' },
            { startTime: '19:00', endTime: '19:20' },
            { startTime: '20:00', endTime: '20:20' },
            { startTime: '21:00', endTime: '21:20' },
            { startTime: '22:00', endTime: '22:20' }
          ]
        },
        newSlot: {
          startTime: '',
          endTime: ''
        }
      };
    },
    methods: {
      showDrawer() {
        this.drawerVisible = true;
      },
      handleClose() {
        this.drawerVisible = false;
      },
      removeTimeSlot(index) {
        this.config.timeSlots.splice(index, 1);
      },
      addTimeSlot() {
        if (!this.newSlot.startTime || !this.newSlot.endTime) {
          this.$message.error('请填写完整的时间段信息');
          return;
        }
        
        this.config.timeSlots.push({
          startTime: this.newSlot.startTime,
          endTime: this.newSlot.endTime
        });
        
        this.newSlot = { startTime: '', endTime: '' };
        this.$message.success('新时间段添加成功');
      },
      saveConfig() {
        this.$emit('save', this.config);
        this.handleClose();
      }
    }
  };
  </script>
  
  <style scoped>
  .drawer-content {
    padding: 20px;
  }
  .drawer-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px;
    text-align: right;
    background-color: #fff;
    border-top: 1px solid #e8e8e8;
  }
  </style>