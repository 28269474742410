
<template>
  <div class="BOX">
    <!-- <img src="../../assets/login_svg.png">
    <div class="inputbox">
      <div class="title">欢迎登录</div>
      <div class="InPut">
        <p>用户名</p>
        <a-input v-model="form.account" placeholder="登录账号" />
      </div>
      <div class="InPut">
        <p>密码</p>
        <a-input v-model="form.password" type="password"  placeholder="登录密码"/>
      </div>
      <el-button
       class="btn"
            :loading="loading"
            type="primary"
            style="width:100%;margin-top:10px;"
            @click.native.prevent="toLogin"
            >立即登录</el-button
          >
    </div> -->
    <div class="box-content">
        <div class="box-left">
          <img class="sw-img" src="../../assets/login_svg.png">
        </div>
        <!-- 账号密码登录-->
        <div class="box-right" v-if="accountModal=='login'">
          <div class="inputbox">
            <div class="title">{{ title }}</div>
            <div class="InPut">
              <p>用户名</p>
              <el-input v-model="form.account" prefix-icon="el-icon-s-custom" placeholder="请输入登录账号" />
            </div>
            <div class="InPut">
              <p>密码</p>
              <el-input class="elinput" v-model="form.password" prefix-icon="el-icon-lock" type="password"  placeholder="请输入登录密码"/>
              <div class="forget" @click="accountLogin">手机号登录</div>
            </div>
            <el-button
            class="btn"
                  :loading="loading"
                  type="primary"
                  @click.native.prevent="toLogin"
                  >立即登录</el-button
                >
                <div class="aggreement">
                  点击登录代表同意<a href="/user.html" target="_blank">《用户协议》</a>与<a href="rule.html" target="_blank">《隐私政策》</a>
                </div>
                <div class="aggreement" style="margin-top: 20px;color: #1890ff;cursor: pointer;" @click="accountModal='register'">还没有账号,去注册</div>
          </div>
          
        </div>
        <!-- 手机号登录-->
        <div class="box-right" v-if="accountModal=='mobile'">
          <div class="inputbox">
            <div class="title">{{ title }}</div>
            <div class="InPut">
              <p>手机号</p>
              <el-input v-model="form.account" prefix-icon="el-icon-s-custom" placeholder="请输入手机号" />
            </div>
            <div class="InPut">
              <p>验证码</p>
              <div style="display: flex;">
                <el-input class="elinput" v-model="form.code" prefix-icon="el-icon-lock"  style="border-top-right-radius: 0;border-bottom-right-radius: 0;"  placeholder="请输入验证码"/>
                <el-button style="width: 80px;height: 48px;" type="primary" size="mini" :disabled="disabled" :style="{background:disabled?'#ccc !important ':'#409EFF'}" @click="sendCode">{{code_text}}</el-button>
              </div>
              <div class="forget"  @click="accountModal='login'">密码登录</div>
            </div> 
             
              <el-button class="btn" :loading="loading"  type="primary"  @click.native.prevent="smsLogin" >立即登录</el-button >
              <div class="aggreement">
                点击登录代表同意<a href="/user.html" target="_blank">《用户协议》</a>与<a href="rule.html" target="_blank">《隐私政策》</a>
              </div>
          </div>
        </div>
         <!-- 注册账号-->
         <div class="box-right" v-if="accountModal=='register'">
          <div class="inputbox">
            <div class="title" style="height: 50px;margin: 0 auto;">注册</div>
            <div class="InPut">
              <p>手机号</p>
              <el-input v-model="form.account" prefix-icon="el-icon-s-custom" placeholder="请输入手机号" />
            </div>
            <div class="InPut">
              <p>验证码</p>
              <div style="display: flex;">
                <el-input class="elinput" v-model="form.code" prefix-icon="el-icon-lock"  style="border-top-right-radius: 0;border-bottom-right-radius: 0;"  placeholder="请输入验证码"/>
                <el-button style="width: 80px;height: 48px;" type="primary" size="mini" :disabled="disabled" :style="{background:disabled?'#ccc !important ':'#409EFF'}" @click="sendCode">{{code_text}}</el-button>
              </div>
            </div> 
            
            <div class="InPut">
              <p>邀请码</p>
              <el-input v-model="form.share_code" prefix-icon="el-icon-message" placeholder="请输入邀请码" />
            </div>
              <el-button class="btn" :loading="loading"  type="primary"  @click.native.prevent="registerAccount" >立即注册</el-button >
              <!-- <div class="aggreement">
                点击登录代表同意<a href="/user.html" target="_blank">《用户协议》</a>与<a href="rule.html" target="_blank">《隐私政策》</a>
              </div> -->
              <div class="aggreement" style="margin-top: 20px;color: #1890ff;cursor: pointer;" @click="accountModal='login'">去登录</div>
          </div>
        </div>
    <!-- 注册账号-->

    </div>
    


    <div class="copyright">
      <a data-v-2414be69="">Copyright © 2023-2024 抠图换图 All Rights Reserved.</a><a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" style="color:#2758FF">备案号：浙ICP备2024106960号-1</a></div>
  </div>
</template>
<script>
import {login,sendCodeApi,mobileLoginApi,registerApi} from '@/api/user.js';
export default {
  data() {
    return {
      loading:false,
      form: {
        account: "",
        password: "",
        code:'',
         share_code:''
      },
      
      title:'欢迎使用',
      accountModal:'login',
      disabled:false,
      code_text:'发送',
      countdown:60,
    
    }
  },

  mounted() {},
  methods: {
    sendCode() {
      // 发送验证码的逻辑
      
      if(this.disabled){
        return this.$message.error('请等待倒计时结束');
      }
      if(!this.form.account){
        return this.$message.error('请填写登录账号');
      }
      
      this.getCode();
    },
    getCode(){
      let that=this;
      sendCodeApi({mobile:this.form.account,'event':'login'}).then(res=>{
        that.$message.success(res.msg);
        if(res.code==1){
          // 设置倒计时
          that.cutDown();
        }else{
          this.loading=false;
        }
      }).catch(res=>{
       
      });
    },
    cutDown(){
      this.disabled = true;
      const timer = setInterval(() => {
        this.countdown--;
        this.code_text = `${this.countdown}秒`;
        if (this.countdown === 0) {
          clearInterval(timer);
          this.disabled = false;
          this.countdown = 60;
          this.code_text = '发送';
        }
      }, 1000);
    },
    accountLogin(){
      let that=this;
      that.title='手机号登录'
      that.accountModal='mobile';
    },
    registerAccount(){
      let that=this;
      
      if(!that.form.account){
        return this.$message.error('请填写注册账号');
      }
      if(!that.form.code){
        return this.$message.error('请填写验证码');
      }
      
      registerApi(that.form).then(res=>{
        console.log(res);
        if(res.code==1){
          that.accountModal='login';
          that.title='账号登录'
          that.form={
            account: "",
            password: "",
            code:'',
            share_code:''
          }
        }
        that.loading=false;
        that.$message.success(res.msg);
       
      }).catch(res=>{
        that.loading=false;
        that.$message.error(res.msg);
       
      });
    },
    toLogin(){
      let that=this;
      
      if(!that.form.account){
        return this.$message.error('请填写登录账号');
      }
      if(!that.form.password){
        return this.$message.error('请填写登录密码');
      }
      that.loading=true;
      that.$store
        .dispatch("user/login", this.form)
        .then(res => {
          console.log(res);
          that.$store
            .dispatch("user/getInfo", {
              that: this
            })
            .then(res => {
              that.loading = false;
              that.$router.push({
                path: "/home"
              });
              
            });
        })
        .catch(error => {
          
          that.$message.error(error.message);
          that.loading = false;
        });
    },

    smsLogin(){
      let that=this;
      if(!that.form.account){
        return this.$message.error('请填写手机号');
      }
      if(!that.form.code){
        return this.$message.error('请填写验证码');
      }
      let logindata={
        mobile:that.form.account,
        code:that.form.code
      }
      that.loading=true;
      that.$store
        .dispatch("user/smslogin",logindata)
        .then(res => {
          console.log(res);
          that.$store
            .dispatch("user/getInfo", {
              that: this
            })
            .then(res => {
              that.loading = false;
              that.$router.push({
                path: "/home"
              });
              
            });
        })
        .catch(error => {
          
          that.$message.error(error.message);
          that.loading = false;
        });
    }
  },
};
</script>
  
  <style scoped  lang="scss">
  :deep(.el-input){
    background-color: #F3F5F9;
    border-radius: 12px;
    border:none ;
    height: 48px !important;
  }
  :deep(.el-input__inner){
    background-color: #F3F5F9 ;
    border-radius: 12px ;
    border:none ;
    height: 42px !important;
  }
  .forget{
    font-size: 13px;
    color:#666666;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    cursor: pointer;
  }
  .aggreement{
    font-size: 12px;
    text-align: center;
  }
  .copyright{
    position: absolute;
    bottom:0;
    left:0;
    right: 0;
    width: 650px;
    margin: 0 auto;
    margin-bottom: 20px;
    a{
      color: #fff;
    }
  }
.BOX {
  width: 100%;
  height: 100vh;
   background: url("@/assets/loginbg.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  border-radius: none;
  .box-content{
    width: 910px;
    height: 500px;
    display: flex;
    border-radius: 10px;
    .box-left{
      width: 510px;
      height: auto;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      .sw-img{
        width: 510px;
        height: 500px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }
    .box-right{
      width: 400px;
      height: 500px;
      background-color: #ffffff;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      box-shadow: 0 0 16px #fff;
    }
  }
  .inputbox {
    padding:20px;
    border-radius: 8px;
    .title {
      width: 200px;
      margin: 20px auto 0 auto;
      height: 80px;
      font-weight: bold;
      font-size: 26px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      justify-content: center;
    }
    .InPut {
      margin: 10px 0;
      padding: 0 6%;
      font-weight: bold;
     
    }
    .btn {
      width: 320px;
      height: 40px;
      background-color: #2d84e7;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px auto;
      border-radius: 6px;
    }

    .btn:hover {
      cursor: pointer;
    }
  }
}
</style>
  