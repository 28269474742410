
<template>
  <div>
    <el-container class="home-container">
      <el-header>
        <img class="logo" src="@/assets/logo.png" alt="" />
        <div class="headrightbox">
          <div class="head1">到期时间：<span>{{userInfo.vip_times || '未开通'}}</span></div>
          <div class="head2">剩余点数：<span>{{userInfo.score || 0}}</span></div>
          <div>
            <img
              class="headimg"
              @click="tologin"
              :src="userInfo.avatar"
              alt=""
            />
            <el-dropdown @command="handleCommand">
              <span
                class="el-dropdown-link"
                style="
                  font-size: 12px;
                  font-weight: 700;
                  margin-left: 10px;
                  cursor: pointer;
                "
              >
                {{userInfo.nickname || userInfo.mobile}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="1">控制台</el-dropdown-item>
                <el-dropdown-item command="2">个人中心</el-dropdown-item>
                <el-dropdown-item command="3">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-header>
      <el-container>
        <div class="leftbox">
          <!-- <div
            v-for="(item, index) in leftlist"
            :key="index"
            @click="leftactive(item, index)"
          >
            <div v-if="item.title && index !== 0" class="xian"></div>
            <div v-if="item.title" class="lefttitle">{{ item.title }}</div>
            <img v-if="item.img" class="leftimg" :src="item.img" alt="" />
            <div
              v-else
              class="iconname"
              :class="activeindex == index ? 'active' : ''"
            >
              <img class="lefticon" v-if="item.icon" :src="item.icon" alt="" />
              <div>{{ item.name }}</div>
              <img
                v-if="activeindex == index"
                class="activeicon"
                src="/image/activeicon.png"
                alt=""
              />
            </div>
          </div>
          <div class="leftbtmbox">
            <div class="lxwm">联系我们</div>
            <div class="yhxybox">
              <span>用户协议</span>
              <span>隐私政策</span>
              <span>关于我们</span>
            </div>
            <div class="yhxybox">京ICP备748184719194有号</div>
          </div> -->
          <el-menu
            default-active="1"
            class="el-menu-vertical-demo"
            :unique-opened="true"
            :router="true"
            active-text-color="#000000"
            text-color="#000000"
            @open="handleOpen"
            @close="handleClose"
            @select="chooseNav"
          >
            <div class="lefttitle">数据</div>
            <el-menu-item index="/home">
              <img class="nav-icon" src="../../assets/a1.png" />
              <span class="nav-title" slot="title">首页</span>
            </el-menu-item>
            <div class="xian"></div>
            <div class="lefttitle">探索</div>
            <el-submenu index="1">
              <template slot="title">
                <img class="nav-icon" src="../../assets/a2.png" />
                <span>商品库</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="shop">商品列表</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="2">
              <template slot="title">
                <img class="nav-icon" src="../../assets/a3.png" />
                <span>视频库</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="video">视频列表</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">
                <img class="nav-icon" src="../../assets/a4.png" />
                <span>货架管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="shopCenter">货架管理</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <!-- <el-submenu index="4">
              <template slot="title">
                <img class="nav-icon" src="../../assets/a8.png" />
                <span>图文素材</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="source">素材首页</el-menu-item>
              </el-menu-item-group>
            </el-submenu> -->
            <div class="xian"></div>
            <div class="lefttitle">创作</div>

            <el-menu-item index="taskAdd" style="height: auto">
              <!-- <i class="el-icon-menu"></i>
              <span slot="title">导航二</span> -->
              <img
                v-if="choose_nav == 'taskAdd'"
                src="../../assets/a51.png"
                style="
                  width: 210px;
                  height: 76px;
                  margin-top: 10px;
                  margin-bottom: 14px;
                "
              />
              <img
                v-else
                src="../../assets/a5.png"
                style="
                  width: 210px;
                  height: 76px;
                  margin-top: 10px;
                  margin-bottom: 14px;
                "
              />
            </el-menu-item>
            <div class="xian"></div>
            <div class="lefttitle">服务</div>
            <el-menu-item index="taskVideo">
              <img class="nav-icon" src="../../assets/a10.png" />
              <span slot="title">发布任务</span>
            </el-menu-item>
            <el-menu-item index="task">
              <img class="nav-icon" src="../../assets/a11.png" />
              <span slot="title">智能剪辑</span>
            </el-menu-item>
            <el-menu-item index="account">
              <img class="nav-icon" src="../../assets/a6.png" />
              <span slot="title">账号绑定</span>
            </el-menu-item>
            <el-menu-item index="shoplist">
              <img class="nav-icon" src="../../assets/a6.png" />
              <span slot="title">达人绑定</span>
            </el-menu-item>
            
            <el-menu-item index="vip">
              <img class="nav-icon" src="../../assets/a7.png" />
              <span slot="title">会员中心</span>
            </el-menu-item>
          </el-menu>
          <!-- <div class="leftbtmbox">
            <div class="lxwm">联系我们</div>
            <div class="yhxybox">
              <span class="bt1">用户协议</span>
              <span class="bt2">隐私政策</span>
              <span class="bt3">关于我们</span>
            </div>
            <div class="yhxybox bt4">京ICP备748184719194有号</div>
          </div> -->
        </div>

        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
   
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      choose_nav: "home",
      visible: false,
      dialogClass: "padding:0",
      login: { mobile: "", password: "" },
      leftlist: [
        {
          title: "数据",
          // icon: require("/public/image/syicon.png"),
          name: "首页",
          img: false,
          url: "/",
        },
        {
          title: "探索",
          // icon: require("/public/image/spkicon.png"),
          name: "商品库",
          img: false,
          url: "/shop",
        },
        {
          title: "",
          // icon: require("/public/image/shipin.png"),
          name: "视频库",
          img: false,
          url: "/video",
        },
        {
          title: "",
          // icon: require("/public/image/ypkicon.png"),
          name: "货架管理",
          img: false,
          url: "/shopCenter",
        },
        {
          title: "创作",
          icon: "",
          name: "",
          // img: require("/public/image/spzzimg.png"),
          url: "taskAdd",
        },
        {
          title: "服务",
          // icon: require("/public/image/zhbdicon.png"),
          name: "账号绑定",
          img: false,
          url: "/account",
        },
        {
          title: "",
          // icon: require("/public/image/hyzxicon.png"),
          name: "会员中心",
          img: false,
          url: "/vip",
        },
      ],
      activeindex: 0,
      URL: "/",
      jjsplist: [
        {
          title: "剪辑视频",
          icon: "/image/jjsp.png",
          number: 1,
          remain: "不限",
        },
        {
          title: "授权账号",
          icon: "/image/qyhsq.png",
          number: 1,
          remain: "不限",
        },
        {
          title: "任务数量",
          icon: "/image/ptdysq.png",
          number: 1,
          remain: "不限",
        },
        {
          title: "视频数据",
          icon: "/image/spsj.png",
          number: 1,
          remain: "不限",
        },
      ],
      list: [
        {
          title: "今日消耗",
          number: 1,
          img: "/image/jrxh.png",
        },
        {
          title: "历史总消耗",
          number: 1,
          img: "/image/lszxh.png",
        },
        {
          title: "历史总充值",
          number: 1,
          img: "/image/lszcz.png",
        },
        {
          title: "管理账号数",
          number: 1,
          img: "/image/glzhs.png",
        },
        { title: "余额", number: 1, img: "/image/yue.png" },
      ],
    
    };
  },
  computed:{
    ...mapGetters(['user','userInfo'])
  },
  mounted() {
    // this.$router.push("/home");
   console.log(this.userInfo);
  },
  methods: {
    ...mapActions('user', ['getInfo']),
   
    handleCommand(e) {
      console.log(e);
      if(e==1){
        this.$router.replace('/home');
      }
      if(e==2){
        this.$router.replace('/vip');
      }
      if(e==3){
        this.loginOut();
      }
    },
    tologin() {
      this.$router.replace("/login");
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    leftactive(item, index) {
      this.activeindex = index;
      if (this.URL !== item.url) {
        this.$router.push(item.url);
      }
      console.log(item.url);
      this.URL = item.url;
    },
    chooseNav(item) {
      this.choose_nav = item;
      
    },
    loginOut(){
      this.$confirm('此操作将退出登录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          setTimeout(async () => {
            await this.$store.dispatch("user/logout");
            this.$router.push(
              `/login?redirect=${this.$route.fullPath}`
            );
          }, 150);
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    }
  },
};
</script>

<style scoped  lang="scss">
/* 修改垂直滚动条 */
::-webkit-scrollbar {
  width: 10px; /* 修改宽度 */
  border-radius: 20px;
}
 
/* 修改滚动条轨道背景色 */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 20px;
}
 
/* 修改滚动条滑块颜色 */
::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 20px;
}
 
/* 修改滚动条滑块悬停时的颜色 */
::-webkit-scrollbar-thumb:hover {
  background-color: #ccc;
}

:deep(body){
  margin: 0 !important;
}
:deep(.el-main){
  padding:0 !important;
  margin:  10px;
}

:deep(.is-active) {
  background-color: #f4f5f9 !important;
  border-radius: 8px !important;
}
:deep(.el-submenu__title:hover, .el-submenu__title:focus) {
  background: #f4f5f9 !important;
  border-radius: 8px !important;
}

*{
            margin: 0;
            padding: 0;
}

.el-menu-item:hover {
  background: #f4f5f9 !important;
  border-radius: 8px !important;
}
.nav-icon {
  width: 14px;
  height: 14px;
  margin-right: 8px;
}
.nav-title {
  font-size: 14px;
}
.BOX-card{
  border-radius: 10px;
}
.BOX {
  width: 100%;
  height: 100%;
  background-size: 130% 130%;
  .tzggbox {
    width: 100%;
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tzggright {
      width: 900px;
      height: 200px;
      background: #aaa;
      margin-top: 20px;
    }

    .tzggleft {
      flex: 1;
      padding: 0 10px;
      box-sizing: border-box;
      .tzggitem {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-weight: 400;
        font-size: 20px;
        color: #666666;
        margin: 4px 0;
      }
    }
  }
}
.tzggtitle {
  font-size: 24px;
  color: #000;
  display: flex;
  align-items: center;
  margin: 25px 0;
}
.tzggicon {
  width: 6px;
  height: 22px;
  margin-right: 4px;
}

.top-data-item {
  width: 302px;
  height: 162px;
  padding: 40px 20px;

  box-sizing: border-box;
  span {
    color: #2758ff;
  }
}

.el-col-lg-4-8 {
  width: 20%;
}

.jjspitem {
  width: 320px;
  height: 190px;
  margin: 20px;
  border: 1px dashed #bbb;
  border-radius: 6px;
  padding: 18px;
  .jjsptitle {
    width: 100%;
    display: flex;
    align-items: center;

    font-weight: 400;
    font-size: 24px;
    color: #000000;
    img {
      width: 36px;
      height: 36px;
    }
  }

  .jjspbtm {
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    span {
      font-weight: 400;
      font-size: 28px;
      color: #000000;
    }
    div {
      display: flex;
      flex-direction: column;
      font-weight: 500;
      font-size: 32px;
      color: #2758ff;
    }
  }
}
body {
  margin: 0 !important;
  padding: 0;
}

.logo {
  width: 120px;
  height: 30px;
}

.headimg {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.home-container {
  height: 100vh;
}

.el-header {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ebedf0;
}

.headrightbox {
  display: flex;
  .head1,
  .head2 {
    font-size: 14px;
    color: #000000;
    margin: 0 20px;
    span {
      color: #2758ff;
    }
  }
  .head2 {
    span {
      color: #d11b1b;
    }
  }
}

.leftbox {
  min-width: 290px;
  padding: 20px 0px 20px 20px;
  box-sizing: border-box;
  position: relative;
}
.iconname:hover {
  cursor: pointer;
}
.leftimg:hover {
  cursor: pointer;
}
.lefttitle {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #878c95;
  padding: 0 10px;
  margin-top: 6px;
}
.xian {
  width: 250px;
  height: 1px;
  background: #ebedf0;
  margin-top: 10px;
  margin-bottom: 20px;
}
.iconname {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
  color: #000000;
  border-radius: 4px;
  width: 230px;
  margin: 4px auto;
  height: 60px;
  position: relative;
  .lefticon {
    width: 20px;
    height: 20px;
    margin: 0 10px;
  }

  .activeicon {
    position: absolute;
    right: 0;
    width: 12px;
    height: 24px;
    top: 18px;
  }
}
.active {
  background: #f4f5f9;
}

.leftimg {
  width: 250px;
  height: 86px;
}
.leftbtmbox {
  cursor: pointer;
  width: 210px;
  margin: 20px;
  box-sizing: border-box;
  .lxwm {
    height: 40px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px dashed #b0b8c4;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 400;
    font-size: 14px;
    color: #666666;
  }
  .lxwm:hover {
    color: #2758ff;
  }
  .yhxybox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
    color: #666666;
    margin: 10px auto;
  }
  .bt1:hover {
    color: #2758ff;
  }
  .bt2:hover {
    color: #2758ff;
  }
  .bt3:hover {
    color: #2758ff;
  }
  .bt4:hover {
    color: #2758ff;
  }
}

.loginbox {
  width: 900px;
  height: 700px;
  // background: url("/public/image/loginbg.png") no-repeat;
  background-size: cover;
  position: relative;
  .inputbox {
    width: 540px;
    height: 700px;
    right: 0;
    top: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .inputtitle {
      font-weight: bold;
      font-size: 38px;
      color: #666666;
    }
    .inputbg {
      padding: 10px;
      background-color: #f3f5f9;
      margin: 10px 0;
      border-radius: 10px;
    }
    .wjmm {
      width: 340px;
      height: 22px;
      font-weight: 400;
      font-size: 16px;
      color: #666666;
      text-align: right;
    }

    .loginbtn {
      width: 340px;
      height: 48px;
      background: #2758ff;
      border-radius: 8px;
      margin-top: 40px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 20px;
    }
    .loginbtn:hover {
      cursor: pointer;
    }
    .wjmm > span:hover {
      cursor: pointer;
    }

    .loginxy {
      width: 340px;
      height: 16px;
      font-size: 16px;
      color: #666666;
      span {
        color: #2758ff;
      }
      span:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
