import request from './request'
/**
 * @description 账号数据
 */
export function accountDataShopApi(){
    return request.get(`shop_account/accountData`);
  }
  
  /**
   * @description 账号列表
   */
  export function accountListShopApi(data){
    return request.get(`shop_account/accountlist`,data);
  }
  
  /**
   * 获取授权URL
   * @param {*} data 
   * @returns 
   */
  export function webUrlShopApi(data){
    return request.post(`shop_account/webUrl`,data);
  }
  

  
  /**
   * 通过code创建用户
   * @param {*} data 
   * @returns 
   */
  export function createAccountShopApi(data){
    return request.post(`shop_account/createAccount`,data);
  }


/**
 * @description 获取分组列表
 */
export function accountGroupList() {
  return request.get(`shop_account/groupList`);
}

export function accountGroupApiList() {
  return request.get(`shop_account/groupAccountList`);
}

/**
* @description 添加新分组
* @param {*} data 
*/
export function addAccountGroup(data) {
  return request.post(`shop_account/addGroup`, data);
}

/**
* @description 删除分组
* @param {*} groupId 
*/
export function deleteAccountGroup(groupId) {
  return request.post(`shop_account/deleteGroup`,{id:groupId});
}

/**
* @description 设置账号所属分组
* @param {*} data 
*/
export function setAccountGroup(data) {
  return request.post(`shop_account/setAccountGroup`, data);
}

export function createTopicApi(data) {
  return request.post(`goods/createTopic`,data);
}

export function topicListApi(data) {
  return request.get(`goods/topicList`,data);
}

export function topicDelApi(data) {
  return request.get(`goods/delTopic`,data);
}
  